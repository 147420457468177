import axios from 'axios'

const api = axios.create({
  baseURL : "https://university.qvolv.com/api",
  // baseURL : "http://localhost:5000/api",
})
export default api;



// import axios from 'axios'

// const api = axios.create({
//   baseURL : "http://localhost:5000/api",
// })
// export default api;
