import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CImage,
} from '@coreui/react';
import logo from '../../../assets/icons/logo-white.png';
import '../../Css/chartsStyle.css';
import axios from '../../../services/api';
import { useFormik } from 'formik';
import { FaUser, FaLock } from "react-icons/fa";
import bgimage from '../../../assets/images/bg-image.jpeg';
import image from '../../../assets/images/Image2.png';
import './login.css'
import CIcon from '@coreui/icons-react'
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";

const Login = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const user_info = JSON.parse(localStorage.getItem('user_info'));
  const [isLoading, setIsLoading] = useState(true);

  const validate = values => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  useEffect(() => {
    if (user_info && location.pathname === '/login') {
      navigate('/');
    }
  }, [user_info, location.pathname, navigate]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validate,
    onSubmit: values => {
      user_login(values);
    }
  });



  async function user_login(values) {
    try {
      const response = await axios.post('/login', { email: values.email, password: values.password });
      if (response.data.user) {
        localStorage.setItem('user_info', JSON.stringify(response.data));
        navigate('/');
      } else {
        setMessage(response.data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Login error', error);
      setMessage('An error occurred. Please try again.');
    }
  }


  useEffect(() => {
    if (user_info && location.pathname === '/login') {
      setIsLoading(true);
      const timer = setTimeout(() => {
        navigate('/');
      }, 100);

      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [user_info, location.pathname, navigate]);

  if (isLoading) {
    return <div></div>;
  }


  return (
    <>
      <div>
        <div className='login-background'>

          <img src={image} className='imageee' />
          <div className='header-img'>
            <CImage src={logo} className='login-mainlogo' />
          </div>
          <div className='boxx'>
            <div>
              <img src={bgimage} className='backgroundimage' loading='lazy' />
            </div>
            <div className='formbox'>

              <div class="form-container" onSubmit={formik.handleSubmit}>
                <h2 className='icon-wrapper1'><FaUser size={24} className='icon-user' /></h2>
                <form id="signup-form">
                  <p className='text-white'>Sign In to your account</p>
                  <p className="text-danger">{message}</p>
                  <p className="text-warning field_validate_label" style={{ textAlign: 'left' }}>{formik.errors.email ? formik.errors.email : null}</p>
                  <div className='input-container'>
                    <FaRegUser className="icon-container"/>

                    <input type="email" id="email" placeholder="E-mail" autoComplete="username" value={formik.values.email} onChange={formik.handleChange} />
                  </div>
                  <p className="text-warning field_validate_label" style={{ textAlign: 'left' }}>{formik.errors.password ? formik.errors.password : null}</p>
                  <div className='input-container'>
                 < RiLockPasswordLine className="icon-container"/>

                    <input type="password" id="password" placeholder="Password" autoComplete="current-password" value={formik.values.password} onChange={formik.handleChange} />
                  </div>
                  <div className='btn-div'>

                    <button type="submit">Login</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
